<template>
  <div class="to_purchase">
    <div class="nav">
      <Header></Header>
      <div class="nav_flex">
        <div class="title">{{ title }}</div>
        <!-- <img class="title" :src="require('./static/image/title.png')" alt="" /> -->
        <div class="search">
          <img :src="require('./static/image/search.png')"
               alt="" />
          <input v-model="keyword"
                 type="text"
                 placeholder="请输入关键词"
                 placeholder_class="placeholder_class"
                 @input="searchInput()" />
          <div class="button"
               @click="searchgetNoticeList()">搜索</div>
        </div>
      </div>
    </div>
    <div class="info">
      <div class="info_div">
        <div class="title">
          <div class="name">自行采购公告</div>
          <img class="icon"
               :src="require('./static/image/announcement.png')"
               alt="" />
        </div>
        <div class="list">
          <div class="list_left">
            <div :class="chanceIndex1 == 0 ? 'active' : ''"
                 @click="toChanceIndex1(0)">
              自行采购
            </div>
            <div :class="chanceIndex1 == 1 ? 'active' : ''"
                 @click="toChanceIndex1(1)">
              招标公告
            </div>
            <div :class="chanceIndex1 == 2 ? 'active' : ''"
                 @click="toChanceIndex1(2)">
              结果公告
            </div>
            <div :class="chanceIndex1 == 3 ? 'active' : ''"
                 @click="toChanceIndex1(3)">
              补充公告
            </div>
          </div>
          <div class="list_right">
            <div class="list_right_top">
              <div :class="chanceIndex2 == 1 ? 'active' : ''"
                   @click="toChanceIndex2(1)">
                省级
                <img v-if="chanceIndex2 == 1"
                     :src="require('./static/image/line.png')"
                     alt="" />
              </div>
              <div :class="chanceIndex2 == 2 ? 'active' : ''"
                   @click="toChanceIndex2(2)">
                市级
                <img v-if="chanceIndex2 == 2"
                     :src="require('./static/image/line.png')"
                     alt="" />
              </div>
              <div :class="chanceIndex2 == 3 ? 'active' : ''"
                   @click="toChanceIndex2(3)">
                区县级
                <img v-if="chanceIndex2 == 3"
                     :src="require('./static/image/line.png')"
                     alt="" />
              </div>
            </div>
            <div class="list_div">
              <div class="list_tr">
                <div class="list_th">区域</div>
                <div class="list_th">采购单位</div>
                <div class="list_th">项目名称</div>
                <div class="list_th">开标时间</div>
                <div class="list_th">发布时间</div>
              </div>
              <div class="list_tr"
                   v-for="(item, index) in dataArr"
                   :key="index"
                   @click="toListDetail(item.noticeId, 1)">
                <div class="list_td"
                     v-if="item.projectArea == 0">省级</div>
                <div class="list_td"
                     v-else-if="item.projectArea == 1">
                  市级
                </div>
                <div class="list_td"
                     v-else-if="item.projectArea == 2">
                  区县级
                </div>
                <div class="list_td"
                     v-else></div>
                <div class="list_td">{{ item.entrustGroup }}</div>
                <div class="list_td">{{ item.projectName }}</div>
                <div class="list_td">{{ item.bidStartTime }}</div>
                <div class="list_td">{{ item.createTimeDate }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="paging">
          <Page :total="page.total"
                size="default"
                :page-size-opts="[10, 20, 30, 40]"
                show-total
                show-elevator
                show-sizer
                @on-change="onChange"
                @on-page-size-change="onSizeChange" />
        </div>
      </div>
    </div>
    <Fotter></Fotter>
  </div>
</template>
<script>
import Header from "../../components/common/header.vue";
import Fotter from "../../components/common/fotter.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import { getNoticeList } from "../../request/home.js";
export default {
  name: "to_purchase",
  components: { Header, Fotter },
  data () {
    return {
      chanceIndex1: 0,
      chanceIndex2: 2,
      dataArr: [],
      keyword: "",
      page: {
        current: 1,
        size: 10,
        total: 0,
        keyword: "",
      },
    };
  },
  computed: {
    ...mapState({
      title: (state) => state.title.title,
    }),
  },
  mounted () {
    this.getNoticeList();
  },
  methods: {
    toChanceIndex1 (index) {
      this.chanceIndex1 = index;
      this.getNoticeList();
    },
    toChanceIndex2 (index) {
      this.chanceIndex2 = index;
      this.getNoticeList();
    },
    toListDetail (id) {
      this.$router.push({
        path: "/list_detail",
        query: { noticeId: id, type1: 1, type2: this.chanceIndex1 },
      });
    },
    onChange (page) {
      this.page.current = page;
      this.getNoticeList();
    },
    onSizeChange (pageSize) {
      this.page.size = pageSize;
      this.getNoticeList();
    },
    getNoticeList () {
      getNoticeList({
        // 自行采购 0  招标公告 1  结果公告 2   补充公告 3
        noticeType: this.chanceIndex1,
        //  省级 0 市 1 区 2
        projectArea: this.chanceIndex2,
        current: this.page.current,
        size: this.page.size,
        keyword: this.page.keyword,
      }).then((res) => {
        this.dataArr = res.data.records;
        this.page.total = res.data.total;
      });
    },
    searchInput () {
      if (this.keyword == "") {
        this.searchgetNoticeList();
      }
    },
    searchgetNoticeList () {
      this.page.current = 1;
      this.page.keyword = this.keyword;
      this.getNoticeList();
    },
  },
};
</script>
<style lang="less" scoped>
.center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.to_purchase {
  // width: 1920px;
  min-height: 100vh;
  background: #f2f3f7;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 150px;

  .nav {
    height: 251px;
    background-image: url("./static/image/nav.png");
    background-size: 100% 100%;

    .nav_flex {
      height: 155px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;

      .title {
        // width: 383px;
        height: 68px;
        margin-right: 202px;
        .center();
        font-size: 48px;
        color: white;
        font-weight: bold;
      }
      .search {
        width: 615px;
        height: 66px;
        background: #ffffff;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding-left: 24px;
        position: relative;

        img {
          width: 22px;
          height: 22px;
          margin-right: 16px;
        }
        input {
          outline: none;
          border: none;
          font-size: 16px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
        }
        /deep/ .placeholder_class {
          color: #b7bcce;
        }
        .button {
          width: 127px;
          height: 50px;
          background: #2957ff;
          font-size: 16px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #ffffff;
          cursor: pointer;
          .center();
          position: absolute;
          right: 8px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
  .info {
    box-sizing: border-box;
    padding: 0 360px;
    padding-top: 24px;

    .info_div {
      box-sizing: border-box;
      padding: 0 24px;
      margin-top: 24px;
      background: #ffffff;
      .title {
        height: 80px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;

        .name {
          font-size: 23px;
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #232428;
          margin-right: 8px;
        }
        .icon {
          width: 51px;
          height: 16px;
        }
      }

      .list {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        .list_left {
          width: 128px;
          margin-right: 24px;

          div {
            width: 128px;
            height: 36px;
            background: #f2f4fc;
            font-size: 14px;
            font-family: PingFang-SC-Regular, PingFang-SC;
            font-weight: 400;
            color: #696d7d;
            margin-bottom: 12px;
            cursor: pointer;
            .center();
          }
          .active {
            background: #2957ff;
            color: #ffffff;
          }
        }
        .list_right {
          flex: 1;
          .list_right_top {
            width: 252px;
            height: 36px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;

            div {
              flex: 1;
              height: 36px;
              background: #f2f4fc;
              font-size: 14px;
              font-family: PingFang-SC-Bold, PingFang-SC;
              font-weight: bold;
              color: #696d7d;
              position: relative;
              cursor: pointer;
              .center();
              img {
                width: 8px;
                height: 4px;
                position: absolute;
                bottom: 3px;
                left: 50%;
                transform: translateX(-50%);
              }
            }
            .active {
              color: #2957ff;
              border: 1px solid #2957ff;
            }
          }
          .list_div {
            .list_tr {
              height: 54px;
              display: flex;
              flex-flow: row nowrap;
              justify-content: flex-start;
              align-items: center;
              text-align: left;
              border-top: 1px dashed #c7ccdd;
              cursor: pointer;

              .list_th {
                font-size: 14px;
                font-family: PingFang-SC-Bold, PingFang-SC;
                font-weight: bold;
                color: #232428;
              }
              .list_td {
                font-size: 16px;
                font-family: PingFang-SC-Regular, PingFang-SC;
                font-weight: 400;
                color: #232428;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }
              .list_th:nth-child(1),
              .list_td:nth-child(1) {
                flex: 1;
              }
              .list_th:nth-child(2),
              .list_td:nth-child(2) {
                flex: 1.5;
              }
              .list_th:nth-child(3),
              .list_td:nth-child(3) {
                flex: 3;
              }
              .list_th:nth-child(4),
              .list_td:nth-child(4) {
                flex: 1.5;
                margin-left: 20px;
              }
              .list_th:nth-child(5),
              .list_td:nth-child(5) {
                flex: 1.5;
              }
              .list_td:nth-child(4) {
                color: #f49716;
              }
            }
            .list_tr:nth-child(1) {
              height: 60px;
              border: none;
              cursor: default;
            }
          }
        }
      }
    }
    .paging {
      height: 108px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;
    }
  }
}
</style>
